// src/components/CaptchaModal.js

import React, { useState } from 'react';
import Modal from 'react-modal';
import TextCaptcha from './TextCaptcha';
import ImageCaptcha from './ImageCaptcha';
import LogicalCaptcha from './LogicalCaptcha';

const CaptchaModal = ({ isOpen, onRequestClose, onCaptchaSolved }) => {
  const [captchaType, setCaptchaType] = useState(Math.floor(Math.random() * 3));
  const [captchaStatus, setCaptchaStatus] = useState('');

  const handleCaptchaSuccess = () => {
    setCaptchaStatus('success');
    onCaptchaSolved(); // This is the crucial part, ensure this function exists
  };

  const handleCaptchaFailure = () => {
    setCaptchaStatus('failure');
  };

  const handleClose = () => {
    setCaptchaStatus('');
    setCaptchaType(Math.floor(Math.random() * 3));
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Captcha Validation"
      className="fixed inset-0 flex items-center justify-center p-4 z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-md mx-auto">
        <h2 className="text-2xl mb-4 text-center">Captcha Validation</h2>
        {captchaType === 0 && <TextCaptcha onCaptchaSuccess={handleCaptchaSuccess} onCaptchaFailure={handleCaptchaFailure} />}
        {captchaType === 1 && <ImageCaptcha onCaptchaSuccess={handleCaptchaSuccess} onCaptchaFailure={handleCaptchaFailure} />}
        {captchaType === 2 && <LogicalCaptcha onCaptchaSuccess={handleCaptchaSuccess} onCaptchaFailure={handleCaptchaFailure} />}
        {captchaStatus === 'success' && (
          <p className="text-green-700 mt-4 text-center">Correct!</p>
        )}
        {captchaStatus === 'failure' && (
          <p className="text-red-700 mt-4 text-center">Incorrect. Please try again.</p>
        )}
        <div className="flex justify-center mt-4">
          <button
            onClick={handleClose}
            className="px-6 py-2 bg-red-600 text-white rounded-lg shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CaptchaModal;
