import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [userCaptchaAnswer, setUserCaptchaAnswer] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    generateCaptcha();
  }, []);

  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      setMessage('');
      setError('OTP has expired. Please request a new one.');
    }
    return () => clearInterval(countdown);
  }, [otpSent, timer]);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const operations = ['+', '-', '*'];
    const operation = operations[Math.floor(Math.random() * operations.length)];
    let captchaExpression;
    let answer;

    switch (operation) {
      case '+':
        captchaExpression = `${num1} + ${num2}`;
        answer = num1 + num2;
        break;
      case '-':
        captchaExpression = `${num1} - ${num2}`;
        answer = num1 - num2;
        break;
      case '*':
        captchaExpression = `${num1} * ${num2}`;
        answer = num1 * num2;
        break;
      default:
        break;
    }

    setCaptcha(captchaExpression);
    setCaptchaAnswer(answer.toString());
  };

  const handleLogin = async () => {
    if (captchaAnswer !== userCaptchaAnswer) {
      setError('Incorrect captcha answer.');
      setMessage('');
      return;
    }

    try {
      const response = await fetch('https://kconsult.se/backend-php/generate-otp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email: email,
          captchaAnswer: captchaAnswer,
          userCaptchaAnswer: userCaptchaAnswer,
        }),
      });

      const text = await response.text();
      console.log('Response text:', text); // Log the raw response text

      let data;
      try {
        data = JSON.parse(text);
      } catch (err) {
        console.error('Failed to parse JSON:', text); // Log parsing error and raw response
        setError('An unexpected error occurred.');
        setMessage('');
        return;
      }

      if (data.message) {
        setMessage(data.message);
        setError('');
        setOtpSent(true);
        setTimer(60); // Reset the timer
      } else {
        setError(data.error);
        setMessage('');
      }
    } catch (error) {
      console.error('Error:', error); // Log fetch error
      setError('An error occurred while sending OTP.');
      setMessage('');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await fetch('https://kconsult.se/backend-php/verify-otp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email: email,
          token: otp,
        }),
      });

      const text = await response.text();
      console.log('Response text:', text); // Log the raw response text

      let data;
      try {
        data = JSON.parse(text);
      } catch (err) {
        console.error('Failed to parse JSON:', text); // Log parsing error and raw response
        setError('An unexpected error occurred.');
        setMessage('');
        return;
      }

      if (data.message) {
        setMessage(data.message);
        setError('');
        localStorage.setItem('isAdmin', 'true'); // Set isAdmin in localStorage
        navigate('/admin'); // Navigate to the /admin route on successful OTP verification
      } else {
        setError(data.error);
        setMessage('');
      }
    } catch (error) {
      console.error('Error:', error); // Log fetch error
      setError('An error occurred while verifying OTP.');
      setMessage('');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="container mx-auto p-4 max-w-md bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-center">Employee Login</h2>
        <p className="text-center mb-8">
          Please enter your email to receive an OTP (One Time Password) for login. To ensure security, solve the simple arithmetic captcha before proceeding.
        </p>
        {message && <div className="message text-green-600 mb-4">{message}</div>}
        {error && <div className="error text-red-600 mb-4">{error}</div>}
        <div className="mb-4">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <button
            onClick={handleLogin}
            className="w-full bg-custom-blue text-white py-2 px-4 rounded hover:bg-custom-blue-dark transition"
          >
            Send OTP
          </button>
        </div>
        {otpSent && (
          <div className="mb-4">
            <input
              type="text"
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
            <button
              onClick={handleVerifyOtp}
              className="w-full bg-custom-blue text-white py-2 px-4 rounded hover:bg-custom-blue-dark transition"
            >
              Verify OTP
            </button>
            <p className="text-red-500 mt-2">OTP expires in: {timer} seconds</p>
          </div>
        )}
        <div className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <span>{`What is ${captcha}?`}</span>
            <button onClick={generateCaptcha} className="text-blue-500 underline text-sm">Refresh Captcha</button>
          </div>
          <input
            type="text"
            placeholder="Captcha Answer"
            value={userCaptchaAnswer}
            onChange={(e) => setUserCaptchaAnswer(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
