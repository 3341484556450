import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAssignments from '../hooks/useAssignments';
import useJobs from '../hooks/useJobs';
import { generateTOTP, verifyTOTP } from '../utils/totp';
import Modal from 'react-modal';

const secret = 'your_totp_secret';

Modal.setAppElement('#root'); // This is important for accessibility

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    padding: '20px',
    borderRadius: '10px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const Admin = () => {
  const [assignments, setAssignments] = useAssignments();
  const [jobs, setJobs] = useJobs();
  const [newAssignment, setNewAssignment] = useState({ title: '', location: '', duration: '', description: '', rate: '' });
  const [newJob, setNewJob] = useState({ title: '', location: '', type: '', description: '', salary: '' });
  const [editingAssignmentIndex, setEditingAssignmentIndex] = useState(null);
  const [editingJobIndex, setEditingJobIndex] = useState(null);
  const [expandedAssignmentIndex, setExpandedAssignmentIndex] = useState(null);
  const [expandedJobIndex, setExpandedJobIndex] = useState(null);
  const [totp, setTotp] = useState('');
  const [authError, setAuthError] = useState('');
  const [isRemoveAllModalOpen, setIsRemoveAllModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [importMessage, setImportMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin');
    if (!isAdmin) {
      navigate('/login');
    }
    const timer = setTimeout(() => {
      openLogoutModal();
    }, 3600000); // 1 hour in milliseconds

    return () => clearTimeout(timer);
  }, [navigate]);

  const generateId = () => {
    let id;
    do {
      id = Math.floor(10000 + Math.random() * 90000).toString();
    } while (assignments.some(a => a.id === id) || jobs.some(j => j.id === id));
    return id;
  };

  const openRemoveAllModal = () => {
    setIsRemoveAllModalOpen(true);
  };

  const closeRemoveAllModal = () => {
    setIsRemoveAllModalOpen(false);
  };

  const handleRemoveAll = async () => {
    closeRemoveAllModal();
    try {
      const response = await axios.post('https://kconsult.se/backend-php/remove-all.php');
      if (response.data.success) {
        setAssignments([]);
        setJobs([]);
        setSuccessMessage('All entries have been removed successfully.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.error('There was an error removing all data:', error);
    }
  };

  const openLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const handleLogout = () => {
    closeLogoutModal();
    localStorage.removeItem('isAdmin');
    navigate('/login');
  };

  const handleAddAssignment = async () => {
    const assignmentWithId = { ...newAssignment, id: generateId() };
    try {
      const response = await axios.post('https://kconsult.se/backend-php/add-assignment.php', assignmentWithId);
      if (response.data.success) {
        setAssignments(prevAssignments => [...prevAssignments, assignmentWithId]);
        setSuccessMessage('Assignment added successfully.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('There was an error adding the assignment:', error);
    }
    setNewAssignment({ title: '', location: '', duration: '', description: '', rate: '' });
  };

  const handleUpdateAssignment = async () => {
    const updatedAssignment = { ...newAssignment, id: assignments[editingAssignmentIndex].id };
    try {
      const response = await axios.post('https://kconsult.se/backend-php/update-assignment.php', updatedAssignment);
      if (response.data.success) {
        setAssignments(prevAssignments =>
          prevAssignments.map((assignment, index) =>
            index === editingAssignmentIndex ? updatedAssignment : assignment
          )
        );
        setSuccessMessage('Assignment updated successfully.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('There was an error updating the assignment:', error);
    }
    setEditingAssignmentIndex(null);
    setNewAssignment({ title: '', location: '', duration: '', description: '', rate: '' });
  };

  const handleDeleteAssignment = async (index) => {
    const assignment = assignments[index];
    try {
      const response = await axios.post('https://kconsult.se/backend-php/delete-assignment.php', { id: assignment.id });
      if (response.data.success) {
        setAssignments(prevAssignments => prevAssignments.filter((_, i) => i !== index));
        setSuccessMessage('Assignment deleted successfully.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.error('There was an error deleting the assignment:', error);
    }
  };

  const handleEditAssignment = (index) => {
    setNewAssignment(assignments[index]);
    setEditingAssignmentIndex(index);
  };

  const handleToggleExpandAssignment = (index) => {
    setExpandedAssignmentIndex(expandedAssignmentIndex === index ? null : index);
  };

  const handleAddJob = async () => {
    const jobWithId = { ...newJob, id: generateId() };
    try {
      const response = await axios.post('https://kconsult.se/backend-php/add-job.php', jobWithId);
      if (response.data.success) {
        setJobs(prevJobs => [...prevJobs, jobWithId]);
        setSuccessMessage('Job added successfully.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('There was an error adding the job:', error);
    }
    setNewJob({ title: '', location: '', type: '', description: '', salary: '' });
  };

  const handleUpdateJob = async () => {
    const updatedJob = { ...newJob, id: jobs[editingJobIndex].id };
    try {
      const response = await axios.post('https://kconsult.se/backend-php/update-job.php', updatedJob);
      if (response.data.success) {
        setJobs(prevJobs =>
          prevJobs.map((job, index) =>
            index === editingJobIndex ? updatedJob : job
          )
        );
        setSuccessMessage('Job updated successfully.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('There was an error updating the job:', error);
    }
    setEditingJobIndex(null);
    setNewJob({ title: '', location: '', type: '', description: '', salary: '' });
  };

  const handleDeleteJob = async (index) => {
    const job = jobs[index];
    try {
      const response = await axios.post('https://kconsult.se/backend-php/delete-job.php', { id: job.id });
      if (response.data.success) {
        setJobs(prevJobs => prevJobs.filter((_, i) => i !== index));
        setSuccessMessage('Job deleted successfully.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.error('There was an error deleting the job:', error);
    }
  };

  const handleEditJob = (index) => {
    setNewJob(jobs[index]);
    setEditingJobIndex(index);
  };

  const handleToggleExpandJob = (index) => {
    setExpandedJobIndex(expandedJobIndex === index ? null : index);
  };

  const handleImportJSON = (e) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      const data = JSON.parse(event.target.result);
      try {
        const response = await axios.post('https://kconsult.se/backend-php/import-data.php', data);
        if (response.data.success) {
          setAssignments(data.assignments || []);
          setJobs(data.jobs || []);
          setImportMessage(`Importing ${data.assignments.length || 0} assignments and ${data.jobs.length || 0} jobs.`);
          setTimeout(() => setImportMessage(''), 3000); // Clear message after 3 seconds
        } else {
          console.error(response.data.error);
        }
      } catch (error) {
        console.error('There was an error importing data:', error);
      }
    };
    fileReader.readAsText(e.target.files[0]);
    e.target.value = ''; // Reset file input
  };

  const handleExportJSON = () => {
    const data = { assignments, jobs };
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.json';
    link.click();
    URL.revokeObjectURL(url);
    setSuccessMessage('Data exported successfully.');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  const handleTotpSubmit = () => {
    if (verifyTOTP(totp, secret)) {
      localStorage.setItem('isAdmin', 'true');
      setAuthError('');
    } else {
      setAuthError('Invalid authenticator code');
    }
  };

  return (
    <section className="py-12 bg-gray-100 text-custom-blue" style={{ marginTop: '4rem' }}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold">Admin Panel</h2>
          <button onClick={openLogoutModal} className="p-2 bg-red-500 text-white rounded">Logout</button>
        </div>
        {successMessage && <div className="bg-green-500 text-white p-2 rounded mb-4">{successMessage}</div>}
        {importMessage && <div className="bg-blue-500 text-white p-2 rounded mb-4">{importMessage}</div>}
        <div className="mb-12">
          <h3 className="text-2xl font-bold mb-2">{editingAssignmentIndex !== null ? "Edit Assignment" : "Add Assignment"}</h3>
          <input type="text" placeholder="Title" value={newAssignment.title} onChange={(e) => setNewAssignment({ ...newAssignment, title: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <input type="text" placeholder="Location" value={newAssignment.location} onChange={(e) => setNewAssignment({ ...newAssignment, location: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <input type="text" placeholder="Duration" value={newAssignment.duration} onChange={(e) => setNewAssignment({ ...newAssignment, duration: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <textarea placeholder="Description" value={newAssignment.description} onChange={(e) => setNewAssignment({ ...newAssignment, description: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <input type="text" placeholder="Rate (SEK)" value={newAssignment.rate} onChange={(e) => setNewAssignment({ ...newAssignment, rate: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          {editingAssignmentIndex !== null ? (
            <button onClick={handleUpdateAssignment} className="w-full p-2 bg-custom-blue text-white rounded mb-4">Update Assignment</button>
          ) : (
            <button onClick={handleAddAssignment} className="w-full p-2 bg-custom-blue text-white rounded mb-4">Add Assignment</button>
          )}
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 bg-gray-200">ID</th>
                <th className="py-2 px-4 bg-gray-200">Title</th>
                <th className="py-2 px-4 bg-gray-200">Location</th>
                <th className="py-2 px-4 bg-gray-200">Duration</th>
                <th className="py-2 px-4 bg-gray-200">Rate (SEK)</th>
                <th className="py-2 px-4 bg-gray-200">Actions</th>
              </tr>
            </thead>
            <tbody>
              {assignments.map((assignment, index) => (
                <React.Fragment key={assignment.id}>
                  <tr>
                    <td className="py-2 px-4 border">{assignment.id}</td>
                    <td className="py-2 px-4 border">{assignment.title}</td>
                    <td className="py-2 px-4 border">{assignment.location}</td>
                    <td className="py-2 px-4 border">{assignment.duration}</td>
                    <td className="py-2 px-4 border">{assignment.rate}</td>
                    <td className="py-2 px-4 border text-right">
                      <button onClick={() => handleEditAssignment(index)} className="p-2 bg-yellow-500 text-white rounded">Modify</button>
                      <button onClick={() => handleDeleteAssignment(index)} className="p-2 bg-red-500 text-white rounded ml-2">Delete</button>
                      <button onClick={() => handleToggleExpandAssignment(index)} className="p-2 bg-blue-500 text-white rounded ml-2">{expandedAssignmentIndex === index ? 'Hide Details' : 'View Details'}</button>
                    </td>
                  </tr>
                  {expandedAssignmentIndex === index && (
                    <tr>
                      <td colSpan="6" className="p-4 bg-gray-100">
                        <p><strong>Description:</strong> {assignment.description}</p>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <h3 className="text-2xl font-bold mb-2">{editingJobIndex !== null ? "Edit Job" : "Add Job"}</h3>
          <input type="text" placeholder="Title" value={newJob.title} onChange={(e) => setNewJob({ ...newJob, title: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <input type="text" placeholder="Location" value={newJob.location} onChange={(e) => setNewJob({ ...newJob, location: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <input type="text" placeholder="Type" value={newJob.type} onChange={(e) => setNewJob({ ...newJob, type: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <textarea placeholder="Description" value={newJob.description} onChange={(e) => setNewJob({ ...newJob, description: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          <input type="text" placeholder="Salary (SEK)" value={newJob.salary} onChange={(e) => setNewJob({ ...newJob, salary: e.target.value })} className="w-full p-2 mb-2 border border-gray-300 rounded" />
          {editingJobIndex !== null ? (
            <button onClick={handleUpdateJob} className="w-full p-2 bg-custom-blue text-white rounded mb-4">Update Job</button>
          ) : (
            <button onClick={handleAddJob} className="w-full p-2 bg-custom-blue text-white rounded mb-4">Add Job</button>
          )}
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 bg-gray-200">ID</th>
                <th className="py-2 px-4 bg-gray-200">Title</th>
                <th className="py-2 px-4 bg-gray-200">Location</th>
                <th className="py-2 px-4 bg-gray-200">Type</th>
                <th className="py-2 px-4 bg-gray-200">Salary (SEK)</th>
                <th className="py-2 px-4 bg-gray-200">Actions</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job, index) => (
                <React.Fragment key={job.id}>
                  <tr>
                    <td className="py-2 px-4 border">{job.id}</td>
                    <td className="py-2 px-4 border">{job.title}</td>
                    <td className="py-2 px-4 border">{job.location}</td>
                    <td className="py-2 px-4 border">{job.type}</td>
                    <td className="py-2 px-4 border">{job.salary}</td>
                    <td className="py-2 px-4 border text-right">
                      <button onClick={() => handleEditJob(index)} className="p-2 bg-yellow-500 text-white rounded">Modify</button>
                      <button onClick={() => handleDeleteJob(index)} className="p-2 bg-red-500 text-white rounded ml-2">Delete</button>
                      <button onClick={() => handleToggleExpandJob(index)} className="p-2 bg-blue-500 text-white rounded ml-2">{expandedJobIndex === index ? 'Hide Details' : 'View Details'}</button>
                    </td>
                  </tr>
                  {expandedJobIndex === index && (
                    <tr>
                      <td colSpan="6" className="p-4 bg-gray-100">
                        <p><strong>Description:</strong> {job.description}</p>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-8">
          <h3 className="text-2xl font-bold mb-2">Import/Export Data</h3>
          <input type="file" accept="application/json" onChange={handleImportJSON} className="mb-4" />
          <button onClick={handleExportJSON} className="p-2 bg-custom-blue text-white rounded">Export Data</button>
        </div>
        <div className="mt-8">
          <h3 className="text-2xl font-bold mb-2">Remove All Data</h3>
          <button onClick={openRemoveAllModal} className="p-2 bg-red-500 text-white rounded">Remove All Data</button>
        </div>
      </div>

      <Modal
        isOpen={isRemoveAllModalOpen}
        onRequestClose={closeRemoveAllModal}
        style={customStyles}
        contentLabel="Remove All Entries"
      >
        <h2>Remove All Entries</h2>
        <p>Are you sure you want to remove all entries?</p>
        <div className="flex justify-end">
          <button onClick={closeRemoveAllModal} className="p-2 bg-gray-300 text-black rounded mr-2">Cancel</button>
          <button onClick={handleRemoveAll} className="p-2 bg-red-500 text-white rounded">Confirm</button>
        </div>
      </Modal>

      <Modal
        isOpen={isLogoutModalOpen}
        onRequestClose={closeLogoutModal}
        style={customStyles}
        contentLabel="Logout"
      >
        <h2>Logout</h2>
        <p>Are you sure you want to logout?</p>
        <div className="flex justify-end">
          <button onClick={closeLogoutModal} className="p-2 bg-gray-300 text-black rounded mr-2">Cancel</button>
          <button onClick={handleLogout} className="p-2 bg-red-500 text-white rounded">Confirm</button>
        </div>
      </Modal>
    </section>
  );
};

export default Admin;
