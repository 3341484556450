import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import CaptchaModal from '../components/Captcha/CaptchaModal';

Modal.setAppElement('#root');

const JobBoard = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    availability: new Date().toISOString().split('T')[0],
    cv: '',
    expectedSalary: '',
    jobId: ''
  });
  const [fileError, setFileError] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);

  const handleCaptchaSolved = () => {
    setIsCaptchaSolved(true);
    setIsCaptchaModalOpen(false);
    handleSubmit(); // Ensure the form is submitted after captcha is solved
  };

  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 9;

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://kconsult.se/backend-php/get-jobs.php');
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const filteredJobs = jobs.filter(job =>
    job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    job.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  if (!jobs) {
    return <p>Loading...</p>;
  }

  const openJobModal = (job) => {
    setSelectedJob(job);
    setIsJobModalOpen(true);
  };

  const closeJobModal = () => {
    setSelectedJob(null);
    setIsJobModalOpen(false);
  };

  const openApplicationModal = (jobId) => {
    setFormData({ ...formData, jobId });
    setIsApplicationModalOpen(true);
  };

  const closeApplicationModal = () => {
    setIsApplicationModalOpen(false);
    setSubmissionStatus(null);
    setFileError('');
  };

  const handleApplicationSubmit = (e) => {
    e.preventDefault();
    if (!isCaptchaSolved) {
      setIsCaptchaModalOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('availability', formData.availability);
    data.append('cv', formData.cv);
    data.append('expectedSalary', formData.expectedSalary);
    data.append('jobId', formData.jobId);

    try {
      const response = await axios.post('https://kconsult.se/backend-php/send-job-application.php', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      setSubmissionStatus('success');
      setFormData({
        name: '',
        email: '',
        phone: '',
        availability: new Date().toISOString().split('T')[0],
        cv: '',
        expectedSalary: '',
        jobId: ''
      });
    } catch (error) {
      console.error('There was an error sending the email:', error);
      setSubmissionStatus('error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.oasis.opendocument.text'];
    const maxSize = 15 * 1024 * 1024; // 15 MB

    if (!allowedTypes.includes(file.type)) {
      setFileError('Only .doc, .docx, .odt, and .pdf files are allowed.');
      setFormData({ ...formData, cv: '' });
    } else if (file.size > maxSize) {
      setFileError('File size must be less than 15MB.');
      setFormData({ ...formData, cv: '' });
    } else {
      setFileError('');
      setFormData({ ...formData, cv: file });
    }
  };

  const JobCard = ({ job }) => (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-4">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{job.title}</div>
        <p className="text-gray-700 text-base">
          {job.location} - {job.type}
        </p>
        <p className="text-gray-500 text-sm">Job ID: {job.id}</p>
        <div className="flex justify-center mt-4">
          <button onClick={() => openJobModal(job)} className="px-4 py-2 bg-[rgb(31,45,90)] text-white rounded-lg shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105">
            View Details
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <section className="bg-gray-100">
      {/* Hero Section */}
      <div className="w-full">
        <section className="bg-cover bg-center h-96 md:h-[600px] flex items-center justify-center text-center text-white relative z-10" style={{ backgroundImage: "url('/optimized-images/career.jpg')" }}>
          {/* Overlay Layer */}
          <div className="absolute inset-0 bg-[rgb(31,45,90)] opacity-40 z-0"></div>
          <div className="container mx-auto relative z-10">
            <h1 className="text-5xl font-bold mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Career Opportunities</h1>
            <p className="text-xl mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Explore exciting job opportunities and find your next career move with us.</p>
            <div className="relative mb-4 w-1/2 mx-auto">
              <input
                type="text"
                placeholder="Search jobs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-4 border border-gray-300 rounded-lg text-[rgb(31,45,90)]"
              />
              <FaSearch className="absolute top-4 right-4 text-gray-400" />
            </div>
          </div>
        </section>
      </div>
      <div className="container mx-auto text-center px-4 mt-12">
        {/* Job Openings Section */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Job Openings</h2>
          <p className="text-lg mb-4">{filteredJobs.length} job(s) available</p>
        </div>
        {/* Job Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-12">
          {currentJobs.map((job, index) => (
            <JobCard key={index} job={job} />
          ))}
        </div>
        {/* Pagination */}
        <div className="flex justify-center mb-12">
          <ul className="flex">
            {Array.from({ length: Math.ceil(filteredJobs.length / jobsPerPage) }, (_, index) => (
              <li key={index} className={`px-4 py-2 ${currentPage === index + 1 ? 'bg-[rgb(31,45,90)] text-white' : 'bg-white text-[rgb(31,45,90)]'} cursor-pointer`} onClick={() => paginate(index + 1)}>
                {index + 1}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Job Modal */}
      <Modal
        isOpen={isJobModalOpen}
        onRequestClose={closeJobModal}
        contentLabel="Job Details"
        className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-75 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white p-8 rounded shadow-lg w-full max-w-lg mx-auto">
          {selectedJob && (
            <>
              <h2 className="text-2xl mb-4">{selectedJob.title}</h2>
              <p className="text-gray-700 mb-4 whitespace-pre-wrap">{selectedJob.description}</p>
              <div className="flex justify-center">
                <button onClick={() => { closeJobModal(); openApplicationModal(selectedJob.id); }} className="mt-4 px-6 py-2 bg-[rgb(31,45,90)] text-white rounded-lg shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105">
                  Apply for This Job
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
      {/* Application Modal */}
      <Modal
        isOpen={isApplicationModalOpen}
        onRequestClose={closeApplicationModal}
        contentLabel="Apply for Job"
        className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-75 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white p-8 rounded shadow-lg w-full max-w-lg mx-auto">
          <h2 className="text-2xl mb-4">Apply for Job</h2>
          {submissionStatus === 'success' ? (
            <div className="relative p-4 mb-4 text-green-700 bg-green-100 border border-green-400 rounded">
              Thanks for submitting. You will be contacted soon.
              <button onClick={closeApplicationModal} className="absolute top-0 right-0 mt-2 mr-2 text-green-700">X</button>
            </div>
          ) : (
            <form onSubmit={handleApplicationSubmit}>
              <input type="hidden" name="jobId" value={formData.jobId} />
              <div className="mb-2">
                <label className="block mb-1">Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="John Doe"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="your-email@mail.com"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Phone Number:</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="+4672123456"
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Availability:</label>
                <input
                  type="date"
                  name="availability"
                  value={formData.availability}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div className="mb-2 w-full">
                <label className="block mb-1">Expected Salary (SEK):</label>
                <input
                  type="text"
                  name="expectedSalary"
                  value={formData.expectedSalary}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="50000 SEK"
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">CV:</label>
                <input
                  type="file"
                  name="cv"
                  onChange={handleFileChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                {fileError && <p className="text-red-500 text-sm mt-2">{fileError}</p>}
              </div>
              <div className="flex justify-center">
                <button type="button" onClick={closeApplicationModal} className="mr-2 p-2 bg-[rgb(31,45,90)] text-white rounded">Cancel</button>
                <button type="submit" className="p-2 bg-[rgb(31,45,90)] text-white rounded" disabled={!!fileError}>Submit Application</button>
              </div>
            </form>
          )}
        </div>
      </Modal>
      {/* CAPTCHA Modal */}
      <CaptchaModal
        isOpen={isCaptchaModalOpen}
        onRequestClose={() => setIsCaptchaModalOpen(false)}
        onCaptchaSolved={handleCaptchaSolved}
      />
    </section>
  );
};

export default JobBoard;
