import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Freelancers from './pages/Freelancers';
import JobBoard from './pages/JobBoard';
import Contact from './pages/Contact';
import Admin from './pages/Admin';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Recruiting from './pages/Recruiting'; // Import Recruiting page
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsentBanner from './components/CookieConsent';

const AppContent = () => {
  const location = useLocation();

  // Hide Header, Footer, and CookieConsentBanner on the privacy policy page
  const hideHeaderFooter = location.pathname === '/privacy-policy';

  return (
    <div className="flex flex-col min-h-screen">
      {!hideHeaderFooter && <Header />}
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/freelancers" element={<Freelancers />} />
          <Route path="/jobboard" element={<JobBoard />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/recruiting" element={<Recruiting />} />
        </Routes>
      </main>
      {!hideHeaderFooter && <Footer />}
      {!hideHeaderFooter && <CookieConsentBanner />}
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
