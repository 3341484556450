import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4 max-w-2xl mt-16">
      <h2 className="text-3xl font-bold mb-4">Privacy Policy</h2>
      <p className="mb-4">
        At Kolgjini Consulting, we are committed to protecting your privacy. This privacy policy explains how we use any personal information we collect about you when you use this website.
      </p>
      <h3 className="text-2xl font-bold mb-2">What information do we collect about you?</h3>
      <p className="mb-4">
        We collect information about you when you submit your CV or contact us for potential recruitment opportunities. The information we collect includes your name, email address, phone number, and any other details you provide in your CV.
      </p>
      <h3 className="text-2xl font-bold mb-2">How will we use the information about you?</h3>
      <p className="mb-4">
        We collect information about you to match you with potential job opportunities and manage our recruitment processes. If you agree, we may also email you about other opportunities or services we think may be of interest to you.
      </p>
      <p className="mb-4">
        Kolgjini Consulting will not disclose or sell your information to third parties. Your information is used solely for recruitment purposes within our organization.
      </p>
      <h3 className="text-2xl font-bold mb-2">Marketing</h3>
      <p className="mb-4">
        We would like to send you information about job opportunities and services of ours which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date.
      </p>
      <p className="mb-4">
        You have the right at any time to stop us from contacting you for marketing purposes.
      </p>
      <h3 className="text-2xl font-bold mb-2">Cookies</h3>
      <p className="mb-4">
        Cookies are text files placed on your computer to collect standard internet log information and visitor behavior information. This information is used to track visitor use of the website and to compile statistical reports on website activity.
      </p>
      <p className="mb-4">
        For further information, visit <a href="https://www.aboutcookies.org" className="text-blue-500 underline">aboutcookies.org</a> or <a href="https://www.allaboutcookies.org" className="text-blue-500 underline">allaboutcookies.org</a>.
      </p>
      <h3 className="text-2xl font-bold mb-2">Changes to our privacy policy</h3>
      <p className="mb-4">
        We keep our privacy policy under regular review and we will place any updates on this web page. This privacy policy was last updated on [insert date].
      </p>
      <h3 className="text-2xl font-bold mb-2">How to contact us</h3>
      <p>
        Please contact us if you have any questions about our privacy policy or information we hold about you:
      </p>
      <ul className="list-disc list-inside">
        <li>By email: <a href="mailto:ek@kconsult.se" className="text-blue-500 underline">ek@kconsult.se</a></li>
        <li>By phone: +46 76 007 4593</li>
        <li>By mail: Kolgjini Consulting, Hyllie Stationstorg 31, 215 32 Malmö</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
