import { useState, useEffect } from 'react';
import axios from 'axios';

const useAssignments = () => {
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const response = await axios.get('https://kconsult.se/backend-php/get-assignments.php');
        setAssignments(response.data);
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };

    fetchAssignments();
  }, []);

  return [assignments, setAssignments];
};

export default useAssignments;
