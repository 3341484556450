import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import CaptchaModal from '../components/Captcha/CaptchaModal';

Modal.setAppElement('#root');

const Freelancers = () => {
  const [assignments, setAssignments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    availability: new Date().toISOString().split('T')[0],
    cv: '',
    expectedRate: '',
    assignmentId: ''
  });
  const [fileError, setFileError] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);

  const handleCaptchaSolved = () => {
    setIsCaptchaSolved(true);
    setIsCaptchaModalOpen(false);
    handleSubmit(); // Ensure the form is submitted after captcha is solved
  };

  const [currentPage, setCurrentPage] = useState(1);
  const assignmentsPerPage = 9;

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const response = await axios.get('https://kconsult.se/backend-php/get-assignments.php');
        setAssignments(response.data);
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };

    fetchAssignments();
  }, []);

  const filteredAssignments = assignments.filter(assignment =>
    assignment.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    assignment.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastAssignment = currentPage * assignmentsPerPage;
  const indexOfFirstAssignment = indexOfLastAssignment - assignmentsPerPage;
  const currentAssignments = filteredAssignments.slice(indexOfFirstAssignment, indexOfLastAssignment);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  if (!assignments) {
    return <p>Loading...</p>;
  }

  const openAssignmentModal = (assignment) => {
    setSelectedAssignment(assignment);
    setIsAssignmentModalOpen(true);
  };

  const closeAssignmentModal = () => {
    setSelectedAssignment(null);
    setIsAssignmentModalOpen(false);
  };

  const openApplicationModal = (assignmentId) => {
    setFormData({ ...formData, assignmentId });
    setIsApplicationModalOpen(true);
  };

  const closeApplicationModal = () => {
    setIsApplicationModalOpen(false);
    setSubmissionStatus(null);
    setFileError('');
  };

  const handleApplicationSubmit = (e) => {
    e.preventDefault();
    if (!isCaptchaSolved) {
      setIsCaptchaModalOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('availability', formData.availability);
    data.append('cv', formData.cv);
    data.append('expectedRate', formData.expectedRate);
    data.append('assignmentId', formData.assignmentId);

    try {
      const response = await axios.post('https://kconsult.se/backend-php/send-freelancer-application.php', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      setSubmissionStatus('success');
      setFormData({
        name: '',
        email: '',
        phone: '',
        availability: new Date().toISOString().split('T')[0],
        cv: '',
        expectedRate: '',
        assignmentId: ''
      });
    } catch (error) {
      console.error('There was an error sending the email:', error);
      setSubmissionStatus('error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.oasis.opendocument.text'];
    const maxSize = 15 * 1024 * 1024; // 15 MB

    if (!allowedTypes.includes(file.type)) {
      setFileError('Only .doc, .docx, .odt, and .pdf files are allowed.');
      setFormData({ ...formData, cv: '' });
    } else if (file.size > maxSize) {
      setFileError('File size must be less than 15MB.');
      setFormData({ ...formData, cv: '' });
    } else {
      setFileError('');
      setFormData({ ...formData, cv: file });
    }
  };

  const AssignmentCard = ({ assignment }) => (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-4">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{assignment.title}</div>
        <p className="text-gray-700 text-base">
          {assignment.location} - {assignment.duration}
        </p>
        <p className="text-gray-500 text-sm">Assignment ID: {assignment.id}</p>
        <div className="flex justify-center mt-4">
          <button onClick={() => openAssignmentModal(assignment)} className="px-4 py-2 bg-[rgb(31,45,90)] text-white rounded-lg shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105">
            View Details
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <section className="bg-gray-100">
      {/* Hero Section */}
      <div className="w-full">
        <section className="bg-cover bg-center h-96 md:h-[600px] flex items-center justify-center text-center text-white relative z-10" style={{ backgroundImage: "url('/optimized-images/freelancer.jpg')" }}>
          {/* Overlay Layer */}
          <div className="absolute inset-0 bg-[rgb(31,45,90)] opacity-40 z-0"></div>
          <div className="container mx-auto relative z-10">
            <h1 className="text-5xl font-bold mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Freelance Gigs</h1>
            <p className="text-xl mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Find exciting consulting assignments and grow your career with us.</p>
            <div className="relative mb-4 w-1/2 mx-auto">
              <input
                type="text"
                placeholder="Search assignments..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-4 border border-gray-300 rounded-lg text-[rgb(31,45,90)]"
              />
              <FaSearch className="absolute top-4 right-4 text-gray-400" />
            </div>
          </div>
        </section>
      </div>
      <div className="container mx-auto text-center px-4 mt-12">
        {/* Assignments Section */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Assignments</h2>
          <p className="text-lg mb-4">{filteredAssignments.length} assignment(s) available</p>
        </div>
        {/* Assignment Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-12">
          {currentAssignments.map((assignment, index) => (
            <AssignmentCard key={index} assignment={assignment} />
          ))}
        </div>
        {/* Pagination */}
        <div className="flex justify-center mb-12">
          <ul className="flex">
            {Array.from({ length: Math.ceil(filteredAssignments.length / assignmentsPerPage) }, (_, index) => (
              <li key={index} className={`px-4 py-2 ${currentPage === index + 1 ? 'bg-[rgb(31,45,90)] text-white' : 'bg-white text-[rgb(31,45,90)]'} cursor-pointer`} onClick={() => paginate(index + 1)}>
                {index + 1}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Assignment Modal */}
      <Modal
        isOpen={isAssignmentModalOpen}
        onRequestClose={closeAssignmentModal}
        contentLabel="Assignment Details"
        className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-75 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white p-8 rounded shadow-lg w-full max-w-lg mx-auto">
          {selectedAssignment && (
            <>
              <h2 className="text-2xl mb-4">{selectedAssignment.title}</h2>
              <p className="text-gray-700 mb-4 whitespace-pre-wrap">{selectedAssignment.description}</p>
              <div className="flex justify-center">
                <button onClick={() => { closeAssignmentModal(); openApplicationModal(selectedAssignment.id); }} className="mt-4 px-6 py-2 bg-[rgb(31,45,90)] text-white rounded-lg shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105">
                  Apply for This Assignment
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
      {/* Application Modal */}
      <Modal
        isOpen={isApplicationModalOpen}
        onRequestClose={closeApplicationModal}
        contentLabel="Apply for Assignment"
        className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-75 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white p-8 rounded shadow-lg w-full max-w-lg mx-auto">
          <h2 className="text-2xl mb-4">Apply for Assignment</h2>
          {submissionStatus === 'success' ? (
            <div className="relative p-4 mb-4 text-green-700 bg-green-100 border border-green-400 rounded">
              Thanks for submitting. You will be contacted soon.
              <button onClick={closeApplicationModal} className="absolute top-0 right-0 mt-2 mr-2 text-green-700">X</button>
            </div>
          ) : submissionStatus === 'captcha-error' ? (
            <div className="relative p-4 mb-4 text-red-700 bg-red-100 border border-red-400 rounded">
              Incorrect captcha answer. Please try again.
              <button onClick={closeApplicationModal} className="absolute top-0 right-0 mt-2 mr-2 text-red-700">X</button>
            </div>
          ) : (
            <form onSubmit={handleApplicationSubmit}>
              <input type="hidden" name="assignmentId" value={formData.assignmentId} />
              <div className="mb-2">
                <label className="block mb-1">Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="John Doe"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="your-email@mail.com"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Phone Number:</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="+4672123456"
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Availability:</label>
                <input
                  type="date"
                  name="availability"
                  value={formData.availability}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div className="mb-2 w-full">
                <label className="block mb-1">Expected Rate</label>
                <input
                  type="text"
                  name="expectedRate"
                  value={formData.expectedRate}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="800 SEK"
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">CV:</label>
                <input
                  type="file"
                  name="cv"
                  onChange={handleFileChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                {fileError && <p className="text-red-500 text-sm mt-2">{fileError}</p>}
              </div>
              <div className="flex justify-center">
                <button type="button" onClick={closeApplicationModal} className="mr-2 p-2 bg-[rgb(31,45,90)] text-white rounded">Cancel</button>
                <button type="submit" className="p-2 bg-[rgb(31,45,90)] text-white rounded" disabled={!!fileError}>Submit Application</button>
              </div>
            </form>
          )}
        </div>
      </Modal>
      {/* CAPTCHA Modal */}
      <CaptchaModal
        isOpen={isCaptchaModalOpen}
        onRequestClose={() => setIsCaptchaModalOpen(false)}
        onCaptchaSolved={handleCaptchaSolved}
      />
    </section>
  );
};

export default Freelancers;
