import React, { useState } from 'react';
import axios from 'axios';
import CaptchaModal from '../components/Captcha/CaptchaModal';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    cv: null, // Change 'file' to 'cv' to match backend expectation
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [fileError, setFileError] = useState('');

  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);

  const handleCaptchaSolved = () => {
    setIsCaptchaSolved(true);
    setIsCaptchaModalOpen(false);
    handleSubmit(); // Ensure the form is submitted after captcha is solved
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.oasis.opendocument.text'];
      const maxSize = 15 * 1024 * 1024; // 15 MB

      if (!allowedTypes.includes(file.type)) {
        setFileError('Only .doc, .docx, .odt, and .pdf files are allowed.');
        setFormData((prevData) => ({ ...prevData, cv: null })); // Change 'file' to 'cv'
      } else if (file.size > maxSize) {
        setFileError('File size must be less than 15MB.');
        setFormData((prevData) => ({ ...prevData, cv: null })); // Change 'file' to 'cv'
      } else {
        setFileError('');
        setFormData((prevData) => ({ ...prevData, cv: file })); // Change 'file' to 'cv'
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleApplicationSubmit = (e) => {
    e.preventDefault();
    if (!isCaptchaSolved) {
      setIsCaptchaModalOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('message', formData.message);
    if (formData.cv) { // Change 'file' to 'cv'
      data.append('cv', formData.cv); // Change 'file' to 'cv'
    }

    try {
      const response = await axios.post('https://kconsult.se/backend-php/send-contact-email.php', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      setSubmissionStatus('success');
      setFormData({
        name: '',
        email: '',
        message: '',
        cv: null, // Change 'file' to 'cv'
      });
      setIsCaptchaSolved(false);
    } catch (error) {
      console.error('There was an error sending the email:', error);
      setSubmissionStatus('error');
    }
  };

  return (
    <div>
      {/* Header / Hero Section */}
      <section className="bg-cover bg-center h-96 md:h-[600px] flex items-center justify-center text-center text-white relative" style={{ backgroundImage: "url('optimized-images/contact.jpg')" }}>
        {/* Overlay Layer */}
        <div className="absolute inset-0 bg-[rgb(31,45,90)] opacity-40"></div>

        <div className="container mx-auto relative z-10">
          <h1 className="text-5xl font-bold mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Get in Touch</h1>
          <p className="text-xl mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>We are here to assist you with your IT consulting and recruiting needs</p>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-12 bg-gray-100 text-custom-blue">
        <div className="container mx-auto text-center px-4">
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">Send Us a Message</h3>
              <form className="text-left" onSubmit={handleApplicationSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-lg">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-lg">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="message" className="block text-lg">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    rows="5"
                    maxLength="2000"
                    required
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="cv" className="block text-lg">Attach CV (optional)</label> {/* Change 'file' to 'cv' */}
                  <input
                    type="file"
                    id="cv"
                    name="cv" // Change 'file' to 'cv'
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                  {fileError && <p className="text-red-500 text-sm mt-2">{fileError}</p>}
                </div>
                {submissionStatus === 'success' && (
                  <div className="text-green-700 bg-green-100 border border-green-400 rounded p-2 mb-4">
                    Your message has been sent successfully!
                  </div>
                )}
                {submissionStatus === 'error' && (
                  <div className="text-red-700 bg-red-100 border border-red-400 rounded p-2 mb-4">
                    There was an error sending your message. Please try again.
                  </div>
                )}
                <button type="submit" className="w-full p-2 bg-custom-blue text-white rounded" disabled={!!fileError}>Send Message</button>
              </form>
            </div>

            {/* Contact Information Section */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">Our Office</h3>
              <p className="text-lg leading-relaxed mb-4">Hyllie Stationstorg 31<br />215 32 Malmö</p>
              <h3 className="text-2xl font-bold mb-4">Contact Details</h3>
              <p className="text-lg leading-relaxed mb-4">Phone: +46 76 007 4593<br />Email: ek@kconsult.se</p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d564.0684209269384!2d12.975366754061126!3d55.56243344431574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653a14a7cc62303%3A0xb2a3844a1c23e498!2sThe%20Point!5e0!3m2!1ssv!2sse!4v1715798802873!5m2!1ssv!2sse"
                width="100%"
                height="300"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* CAPTCHA Modal */}
      <CaptchaModal
        isOpen={isCaptchaModalOpen}
        onRequestClose={() => setIsCaptchaModalOpen(false)}
        onCaptchaSolved={handleCaptchaSolved}
      />
    </div>
  );
};

export default Contact;
