import React, { useState } from 'react';

const ImageCaptcha = ({ onCaptchaSuccess, onCaptchaFailure }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const images = [
    { src: '/captcha/cat.jpeg', alt: 'cat' },
    { src: '/captcha/dog.jpg', alt: 'dog' },
  ];
  const correctImageAlt = 'cat'; // The correct answer

  const handleImageClick = (alt) => {
    setSelectedImage(alt);
    if (alt === correctImageAlt) {
      onCaptchaSuccess();
    } else {
      onCaptchaFailure();
    }
  };

  return (
    <div>
      <p>Please click on the image of a cat:</p>
      <div className="flex justify-center space-x-4 mt-4">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className={`w-24 h-24 cursor-pointer border-4 ${selectedImage === image.alt ? 'border-blue-500' : 'border-transparent'}`}
            onClick={() => handleImageClick(image.alt)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCaptcha;
