import React, { createContext, useReducer } from 'react';

const initialState = {
  assignments: [],
  jobs: [],
};

const GlobalContext = createContext(initialState);

const SET_ASSIGNMENTS = 'SET_ASSIGNMENTS';
const SET_JOBS = 'SET_JOBS';

const globalReducer = (state, action) => {
  switch (action.type) {
    case SET_ASSIGNMENTS:
      return { ...state, assignments: action.payload };
    case SET_JOBS:
      return { ...state, jobs: action.payload };
    default:
      return state;
  }
};

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const setAssignments = (assignments) => dispatch({ type: SET_ASSIGNMENTS, payload: assignments });
  const setJobs = (jobs) => dispatch({ type: SET_JOBS, payload: jobs });

  return (
    <GlobalContext.Provider value={{ ...state, setAssignments, setJobs }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
