import React from 'react';

const Recruiting = () => {
  return (
    <section className="bg-gray-100">
      {/* Hero Section */}
      <div className="w-full">
        <section className="bg-cover bg-center h-96 md:h-[600px] flex items-center justify-center text-center text-white relative z-10" style={{ backgroundImage: "url('/optimized-images/recruiting.jpg')" }}>
          {/* Overlay Layer */}
          <div className="absolute inset-0 bg-[rgb(31,45,90)] opacity-40 z-0"></div>
          <div className="container mx-auto relative z-10">
            <h1 className="text-5xl font-bold mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>How We Recruit</h1>
            <p className="text-xl mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Our process to find the best candidates for you.</p>
          </div>
        </section>
      </div>

      <div className="container mx-auto text-center px-4 mt-12">
        {/* Recruitment Process Section */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Our Recruitment Process</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">Initial Consultation</h3>
              <p>We start by understanding your needs and the type of candidates you are looking for. This involves discussing your current recruitment process and identifying any challenges you face.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">Sourcing Candidates</h3>
              <p>We utilize various methods to source candidates, including job boards, social media, and our extensive database. Our goal is to find the most qualified individuals who meet your specific requirements.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">Screening</h3>
              <p>Our team conducts thorough screenings to ensure candidates possess the necessary qualifications and fit well with your company culture. This step includes reviewing resumes and conducting initial interviews.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">Interviewing</h3>
              <p>We handle the initial interview process, assessing candidates' skills and suitability for the role. Our IT expertise ensures that technical skills are accurately evaluated.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">Presentation</h3>
              <p>Once we've identified the top candidates, we present them to you for final interviews. We provide detailed profiles and our recommendations to help you make informed decisions.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">Follow-Up</h3>
              <p>Our support doesn't end with the hire. We assist with negotiations and onboarding to ensure a smooth transition for both the candidate and your company. We also offer a 3-month guarantee on all placements.</p>
            </div>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Why Choose Kolgjini Consulting & Recruitment?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">IT Expertise</h3>
              <p>Our recruiters have deep IT knowledge, making the interview and screening process more straightforward and secure for our clients. This expertise allows us to accurately assess technical skills and match the right candidates to your needs.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">Extensive Candidate Database</h3>
              <p>We have a large database of qualified candidates ready to meet your specific needs. Our extensive network ensures that we can find the right fit for your company quickly and efficiently.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-2">3-Month Guarantee</h3>
              <p>If a candidate leaves within three months, we will replace them at no extra cost to you. This guarantee demonstrates our confidence in the quality of our candidates and our commitment to your satisfaction.</p>
            </div>
          </div>
        </div>

        {/* Contact Us Section */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-4">Get in Touch</h2>
          <p className="text-lg mb-4">Ready to streamline your recruitment process? Contact us today to discuss a tailored solution for your business.</p>
          <div className="flex justify-center">
          <a href="/contact" className="inline-block mt-4 px-6 py-2 bg-custom-blue text-white rounded-lg shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105">
            Contact Us
          </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recruiting;
