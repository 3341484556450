// src/components/TextCaptcha.js

import React, { useState, useEffect } from 'react';

const TextCaptcha = ({ onCaptchaSuccess, onCaptchaFailure }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');

  useEffect(() => {
    generateQuestion();
  }, []);

  const generateQuestion = () => {
    const questions = [
      { question: 'What is the color of the sky?', answer: 'blue' },
      { question: 'What is 2 + 2?', answer: '4' },
      { question: 'What is the capital of France?', answer: 'paris' },
    ];
    const randomQuestion = questions[Math.floor(Math.random() * questions.length)];
    setQuestion(randomQuestion.question);
    setAnswer(randomQuestion.answer);
  };

  const handleChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userAnswer.toLowerCase() === answer.toLowerCase()) {
      onCaptchaSuccess();
    } else {
      onCaptchaFailure();
    }
  };

  return (
    <div>
      <p>Answer the following question:</p>
      <p className="font-bold">{question}</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userAnswer}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Enter your answer"
        />
        <button type="submit" className="mt-2 px-4 py-2 bg-blue-600 text-white rounded">
          Submit
        </button>
      </form>
    </div>
  );
};

export default TextCaptcha;
