// src/components/LogicalCaptcha.js

import React, { useState, useEffect } from 'react';

const LogicalCaptcha = ({ onCaptchaSuccess, onCaptchaFailure }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    generateQuestion();
  }, []);

  const generateQuestion = () => {
    const a = Math.floor(Math.random() * 10);
    const b = Math.floor(Math.random() * 10);
    setQuestion(`${a} + ${b}`);
    setAnswer((a + b).toString());
  };

  const handleChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userAnswer === answer) {
      setFeedback('Correct!');
      onCaptchaSuccess();
    } else {
      setFeedback('Incorrect. Please try again.');
      onCaptchaFailure();
    }
  };

  return (
    <div>
      <p>Solve the following:</p>
      <p className="font-bold">{question}</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userAnswer}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Enter your answer"
        />
        <button type="submit" className="mt-2 px-4 py-2 bg-blue-600 text-white rounded">
          Submit
        </button>
      </form>
      {feedback && <p className={feedback === 'Correct!' ? 'text-green-700' : 'text-red-700'}>{feedback}</p>}
    </div>
  );
};

export default LogicalCaptcha;
