import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCheckCircle, faLightbulb, faRocket, faShieldAlt, faFlask, faUsers, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const teamMembers = [
  {
    name: 'Eduard Kolgjini',
    position: 'CEO / Business Manager',
    image: `${process.env.PUBLIC_URL}/team/eduard.jpg`,
    bio: "Eduard Kolgjini, our dynamic CEO and Business Manager, brings over 7 years of consulting and management expertise to our team. His strategic planning and innovative leadership have been pivotal in driving our company's growth and success."
  },
  {
    name: 'Alfred Kolgjini',
    position: 'Business Developer',
    image: `${process.env.PUBLIC_URL}/team/alfred.jpg`,
    bio: "Meet Alfred Kolgjini, the 18-year-old prodigy reshaping the business landscape as our visionary Business Developer. With a blend of youthful dynamism and unmatched tech prowess, Alfred propels us into uncharted realms of innovation and success."
  }
];

const Home = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  return (
    <div>
      {/* Header / Hero Section */}
      <section className="bg-cover bg-center h-96 md:h-[600px] flex items-center justify-center text-center text-white relative" style={{ backgroundImage: "url('/optimized-images/hero.jpg')" }}>
        {/* Overlay Layer */}
        <div className="absolute inset-0 bg-[rgb(31,45,90)] opacity-40"></div>

        <div className="container mx-auto relative z-10">
          <h1 className="text-5xl font-bold mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Empowering Your Vision</h1>
          <p className="text-xl mb-4 hero-text" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Leading the Way in IT Consulting and Talent Acquisition</p>
          <a href="/about">
          </a>
        </div>
      </section>

      {/* "Who We Are" Section */}
      <section className="py-6 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Who We Are</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-8 max-w-3xl mx-auto">
            <p className="text-lg leading-relaxed text-center">
              Kolgjini Consulting AB, established in 2021, has rapidly expanded its services to include top-tier IT consulting and recruiting. Our mission is to provide personalized, innovative solutions that drive business success and foster growth. With a commitment to excellence and a focus on building lasting relationships, we strive to exceed our clients' expectations and empower them to achieve their goals.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faUser} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Personalized Solutions</h3>
              <p className="text-md leading-relaxed">
                Our personalized approach ensures that your unique business needs are met with tailored solutions. Whether you're a startup looking to establish a robust IT infrastructure or a multinational corporation seeking top-tier talent, we have the expertise and resources to meet your needs.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faCheckCircle} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Commitment to Excellence</h3>
              <p className="text-md leading-relaxed">
                Since our inception in 2021, we’ve been committed to providing top-notch IT consulting and recruiting services with a touch of humor and a lot of professionalism. Our expert team guarantees timely delivery and impeccable quality in every project we undertake.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faLightbulb} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Rich Experience</h3>
              <p className="text-md leading-relaxed">
                Our team of experts brings a wealth of experience and a personalized approach to every project. We understand the challenges businesses face in the ever-evolving IT landscape and are here to navigate those challenges with you.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* "Our Services" Section */}
      <section className="py-6 bg-gray-100">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-8">Our Services</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <img src="optimized-images/consulting.jpg" alt="Consulting Service" className="h-48 w-full object-cover mb-4 rounded" />
              <h3 className="text-2xl font-bold mb-4">Consulting</h3>
              <p className="text-lg leading-relaxed">
                Our IT consulting services help you leverage technology to achieve your business goals. We offer strategic guidance, system implementation, and ongoing support to ensure your IT infrastructure is efficient, secure, and scalable.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <img src="optimized-images/recruiting.jpg" alt="Recruiting Service" className="h-48 w-full object-cover mb-4 rounded" />
              <h3 className="text-2xl font-bold mb-4">Recruiting</h3>
              <p className="text-lg leading-relaxed">
                Finding the right talent is crucial for your business success. Our recruiting services connect you with skilled professionals who match your company’s culture and requirements. We handle the entire hiring process, from candidate sourcing to onboarding.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* "Our Team" Section */}
      <section className="py-6 bg-gray-100">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-8">Our Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {teamMembers.map((member, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-lg text-center">
                <img src={member.image} alt={member.name} className="w-20 h-20 object-cover rounded-full mx-auto mb-4" />
                <h4 className="text-xl font-bold mb-2">{member.name}</h4>
                <h5 className="text-md font-semibold mb-2">{member.position}</h5>
                <p className="text-sm">{member.bio}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* "Our Values" Section */}
      <section className="py-6 bg-gray-100">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-8">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faShieldAlt} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2 text-custom-blue">Integrity</h4>
              <p>We adhere to the highest ethical standards, ensuring transparency and honesty in all our actions.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faFlask} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2 text-custom-blue">Innovation</h4>
              <p>We foster a culture of innovation, continuously seeking new ways to deliver top-tier solutions to our clients.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faUsers} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2 text-custom-blue">Customer Focus</h4>
              <p>Our clients are at the core of everything we do. We strive to exceed their expectations with every project.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faLightbulb} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2 text-custom-blue">Expertise</h4>
              <p>We bring a wealth of knowledge and experience to every project, ensuring our clients benefit from industry-leading insights and solutions.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faRocket} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2 text-custom-blue">Agility</h4>
              <p>We are adaptable and responsive, quickly adjusting to changes and new challenges to deliver effective solutions for our clients.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <FontAwesomeIcon icon={faCheckCircle} className="text-3xl text-[rgb(31,45,90)] mx-auto mb-4" />
              <h4 className="text-2xl font-bold mb-2 text-custom-blue">Commitment</h4>
              <p>We are dedicated to our clients' success, going above and beyond to ensure their goals are met and their expectations are exceeded.</p>
            </div>
          </div>
        </div>
      </section>

      {/* "Get in Touch" Section */}
      <section className="py-6 bg-gray-100">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-4 text-custom-blue">Get in Touch</h2>
          <p className="text-lg leading-relaxed max-w-2xl mx-auto">
            We are always here to help you. Contact us today to learn more about how our services can benefit your business.
          </p>
          <a href="/contact" className="inline-block mt-4 px-6 py-2 bg-custom-blue text-white rounded-lg shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105">
            Contact Us
          </a>
        </div>
      </section>

      {/* Scroll to Top Button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-4 right-4 p-2 rounded-full bg-[rgb(31,45,90)] text-white text-2xl shadow-md hover:bg-blue-700 transition-opacity duration-200 ${showScroll ? 'opacity-100' : 'opacity-0'}`}
        aria-label="Scroll to Top"
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </div>
  );
};

export default Home;
