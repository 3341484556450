import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      declineButtonText="Decline"
      enableDeclineButton
      onDecline={() => {
        console.log('Cookies declined');
      }}
      cookieName="mySiteCookieConsent"
      style={{ background: "#1F2D5A" }}
      buttonStyle={{ color: "#1F2D5A", background: "#ffffff", fontSize: "13px" }}
      declineButtonStyle={{ color: "#1F2D5A", background: "#ffffff", fontSize: "13px" }}
      expires={365}
    >
      This website uses cookies to enhance the user experience. By using this site, you agree to our use of cookies. 
      For more information, please visit our <a href="/privacy-policy" style={{ color: "#ffffff" }}><b>Privacy Policy</b></a>.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
