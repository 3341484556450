import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser, faBriefcase, faHandshake, faRightToBracket, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { animateScroll as scroll } from 'react-scroll';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  const handleNavigationClick = (path) => {
    navigate(path);
    scroll.scrollToTop();
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`p-4 bg-custom-blue text-white shadow-lg fixed w-full z-50 top-0 transition-all duration-300 ${scrolled ? 'scrolled' : ''}`}>
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => handleNavigationClick('/')}>
              <img
                src={`${process.env.PUBLIC_URL}/optimized-images/logo-color-new-2-tp.png`}
                alt="Kolgjini Consulting AB"
                className="logo mr-4"
              />
            </button>
          </div>
          <div className="menu-icon md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? <AiOutlineClose size={30} color="#FFFFFF" /> : <AiOutlineMenu size={30} color="#FFFFFF" />}
          </div>
          <nav className={`fixed top-16 right-0 w-64 bg-custom-blue z-50 transform ${menuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:static md:transform-none md:w-auto md:h-auto md:bg-transparent md:z-auto`}>
            <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-16 md:mt-0 md:items-center text-sm">
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }}>
                <button className="nav-link" onClick={() => handleNavigationClick('/')}>
                  <FontAwesomeIcon icon={faBuilding} className="mr-2" /> Who We Are
                </button>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }}>
                <button className="nav-link" onClick={() => handleNavigationClick('/recruiting')}>
                  <FontAwesomeIcon icon={faHandshake} className="mr-2" /> How We Recruit
                </button>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }}>
                <button className="nav-link" onClick={() => handleNavigationClick('/freelancers')}>
                  <FontAwesomeIcon icon={faUser} className="mr-2" /> Freelance
                </button>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }}>
                <button className="nav-link" onClick={() => handleNavigationClick('/jobboard')}>
                  <FontAwesomeIcon icon={faBriefcase} className="mr-2" /> Career
                </button>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }}>
                <button className="nav-link" onClick={() => handleNavigationClick('/contact')}>
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> Contact
                </button>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }} className="mt-4 md:mt-0 md:ml-4">
                <button className="nav-link login-button" onClick={() => handleNavigationClick('/login')}>
                  <FontAwesomeIcon icon={faRightToBracket} className="mr-2" /> Login
                </button>
              </motion.li>
            </ul>
          </nav>
        </div>
      </header>
      <div className="pt-16"> {/* Adjust the padding-top value based on the header height */}
        {/* Main content goes here */}
      </div>
    </>
  );
};

export default Header;
