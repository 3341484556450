import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { motion } from 'framer-motion';

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigationClick = (path) => {
    navigate(path);
    scroll.scrollToTop();
  };

  return (
    <footer className="py-8 bg-custom-blue text-white">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
        {/* Quick Links */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
          <ul>
            <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }} className="mb-2">
              <button className="hover:underline" onClick={() => handleNavigationClick('/')}>Who We Are</button>
            </motion.li>
            <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }} className="mb-2">
              <button className="hover:underline" onClick={() => handleNavigationClick('/recruiting')}>How We Recruit</button>
            </motion.li>
            <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }} className="mb-2">
              <button className="hover:underline" onClick={() => handleNavigationClick('/freelancers')}>Freelance</button>
            </motion.li>
            <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }} className="mb-2">
              <button className="hover:underline" onClick={() => handleNavigationClick('/jobboard')}>Career</button>
            </motion.li>
            <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }} className="mb-2">
              <button className="hover:underline" onClick={() => handleNavigationClick('/contact')}>Contact</button>
            </motion.li>
            <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.3 }} className="mb-2">
              <RouterLink to="/privacy-policy" className="hover:underline" target="_blank" rel="noopener noreferrer">Privacy Policy</RouterLink>
            </motion.li>
          </ul>
        </div>
        {/* Contact Info */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Contact Info</h4>
          <address className="not-italic">
            <p>The Point<br />
              Hyllie Stationstorg 31, Malmö</p>
            <p>Phone: <a href="tel:+46760074593" className="hover:underline">+46 76 007 4593</a></p>
            <p>Email: <a href="mailto:ek@kconsult.se" className="hover:underline">ek@kconsult.se</a></p>
          </address>
        </div>
        {/* About Us */}
        <div>
          <h4 className="text-lg font-semibold mb-4">About Us</h4>
          <p>We are a dedicated Talent Agency committed to empowering ambitious IT professionals. Allow us to unlock your potential and accelerate your path to success.</p>
          <div className="mt-4 flex justify-center md:justify-start">
            <a href="https://www.linkedin.com/company/kolgjini-consulting" className="hover:text-gray-300">
              <FontAwesomeIcon icon={faLinkedin} className="text-2xl" />
            </a>
          </div>
          <p className="mt-4">&copy; 2024 Kolgjini Consulting AB. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
