import { useState, useEffect } from 'react';
import axios from 'axios';

const useJobs = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://kconsult.se/backend-php/get-jobs.php');
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  return [jobs, setJobs];
};

export default useJobs;
